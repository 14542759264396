import { put, call, takeEvery } from 'redux-saga/effects';
import { faqDataLoaded } from './actions';
import { getAnswerQuestionData } from '../../api/common';
import { GET_FAQ_DATA } from './constants';

function* loadFaqData() {
  const response = yield call(getAnswerQuestionData);  
  yield put(faqDataLoaded(response));
}

export function* faqSaga() {
  yield takeEvery(GET_FAQ_DATA, loadFaqData);
}