import React from 'react';
import bem from '../../../lib/bem';
import { formatDate } from '../../../helpers/common';
const block = 'created-cell';

export const DateCreatedCell = (cellData) => {
  return (
    <div className={bem({ block })}>
      { formatDate(cellData.data.created) }
    </div>
  );
};
