import React from 'react';
import {STATUS_WORK, STATUS_NEW, STATUS_REJECTED, STATUS_DONE} from '../../../constants/statuses';
import bem from '../../../lib/bem';
import './style.css';

const block = 'status-cell';

export const StatusCellWithEmergency = (cellData) => {
  const getStatusMods = () => {
    return cellData.value
      ? {
        done: cellData.data.stateId === STATUS_DONE,
        new: cellData.data.stateId === STATUS_NEW,
        'at-work': cellData.data.stateId === STATUS_WORK,
        rejected: cellData.data.stateId === STATUS_REJECTED
      }
      : {};
  };

  return (
    <div className={bem({ block })}>
      {(cellData.data.isDanger || cellData.data.isPotentialDanger) ?
        <div className={bem({ block, elem: 'emergency' })} /> :
        ''
      }
      <div className={bem({ block, elem: 'name', mods: getStatusMods() })}>
        {cellData.value || cellData.text}
      </div>
    </div>
  );
};
