import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import { LayersControl, Map, ZoomControl } from 'react-leaflet';
import WMTSTileLayer from "react-leaflet-wmts";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import filter from 'lodash/filter';
import moment from 'moment';
import 'react-leaflet-markercluster/dist/styles.min.css';
import * as L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import CityIncidents from '../../components/CityIncidents';
import { MapMarker } from './MapMarker';
import context from '../../api/api';
import './style.css';
import bem from '../../lib/bem';
import sortBy from 'lodash/sortBy';
import { STATUS_DONE, STATUS_NEW, STATUS_WORK, STATUS_REJECTED } from '../../constants/statuses';

const block = 'incidents-leaflet';

// генерация иконки в зависимости от вхождения в кластер маркера с чс
const customIconCreateFunction = (cluster) => {
  let markerClassName = '';
  let clusterClassName = '';

  cluster.getAllChildMarkers().some((marker) => {
    // если содержит хоть 1 чс - весь кластер становится чс
    if (marker.options.icon.options.html.includes('emergency')) {
      clusterClassName = 'marker-cluster-has-emergency';
      markerClassName = 'leaflet-map__leaflet-custom-marker leaflet-map__leaflet-custom-marker_emergency';
      return true;
    }
    return false;
  });

  const childCount = cluster.getChildCount();

  return new L.DivIcon({
    html: `<div><span class='${markerClassName}'>${childCount}</span></div>`,
    className: `marker-cluster marker-cluster-medium ${clusterClassName}`,
    iconSize: new L.Point(40, 40)
  });
};

// сбор и открытие всех попапов маркеров в одном
const customOnClusterClick = (cluster) => {
  const popupHtml = cluster.layer.getAllChildMarkers().map((value) => {
    return renderToStaticMarkup(value.getPopup().options.children);
  });

  cluster.layer.bindPopup(popupHtml.join('')).openPopup();
};

export default class LeafletMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gisServerUri: '',
      gisServerLayers: '',
      incidentStates: [],
    };
    context.settings.getGisServerSettings()
      .then((settings) =>
        this.setState({ gisServerUri: settings.Uri, gisServerLayers: settings.Layers })
      );

    context.incidentState.getList()
      .then(list => {
        this.setState({ incidentStates: list });
      });
  }


  getStatusMods = (status) => {
    return status ? {
      done: status === STATUS_DONE,
      new: status === STATUS_NEW,
      'work': status === STATUS_WORK,
      rejected: status === STATUS_REJECTED
    } : {};
  };

  getMarkersByFilters = () => {
    const { markers, filters } = this.props;

    if (markers
      && markers.length > 0
      && (!filters.type.id || filters.type.id > -1)) {
      return filter(markers, (marker) => {
        let res = true;

        if (filters.type && filters.type.caseTypeId) {
          let parentIndexCode = marker.cardIndexCode.split("|")[0];
          res = res && (marker.serviceTypeId === filters.type.caseTypeId && parentIndexCode === filters.type.code);
        }
        //показывам только с координатами
        res = res && marker.latitude && marker.longitude

        return res;
      });
    }
    return filter(markers, (marker) => {
      //показывам только с координатами
      return marker.latitude && marker.longitude
    });;
  };

  setRefToMap = (node) => {
    this.mapRef = node;
  };

  handleMoveEnd = () => {
    const { saveToUrlMapPosition } = this.props;

    saveToUrlMapPosition(this.mapRef.viewport);
  };

  render() {
    const {
      lat,
      lng,
      zoom,
      withoutIncidents,
      types,
      filters,
      onChangeIncidentsFilter,
    } = this.props;

    const rootTypes = {
      ...types,
      results: types.results.filter(type => type.parentCode === null)
    };

    const center = [lat, lng];

    const markersByFilters = this.getMarkersByFilters();

    const sortedMarkersByDanger = sortBy(markersByFilters, (m) => {
      return [-(m.isDanger), -(m.isPotentialDanger)];
    });

    return (
      <div className={bem({ block })}>
        {withoutIncidents
          ? <div />
          : (
            <Grid fluid>
              <Row>
                <CityIncidents
                  types={rootTypes}
                  filters={filters}
                  onChangeIncidentsFilter={onChangeIncidentsFilter}
                />
              </Row>
            </Grid>
          )}
        <Map
          center={center}
          zoom={zoom}
          zoomControl={false}
          scrollWheelZoom={false}
          maxZoom={17}
          minZoom={5}
          ref={this.setRefToMap}
          onMoveEnd={this.handleMoveEnd}
        >
          <ZoomControl
            zoomInTitle="Увеличить"
            position="topright"
            zoomOutTitle="Уменьшить"
          />
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Streets">
              <WMTSTileLayer
                attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                url={this.state.gisServerUri}
                layers={this.state.gisServerLayers}
                layer={this.state.gisServerLayers}
                tilematrixSet="EPSG:900913"
                srs="EPSG:900913"
                format="image/png"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <MarkerClusterGroup
            maxClusterRadius={30}
            iconCreateFunction={customIconCreateFunction}
            onClusterClick={customOnClusterClick}
            spiderfyOnMaxZoom={false}
            zoomToBoundsOnClick={false}
            removeOutsideVisibleBounds
          >
            {sortedMarkersByDanger.sort(function (s) {
              return -(new Date(s.created));
            }).map((marker, index) => {
              return (
                <MapMarker key={`${marker.incidentId + marker.cardId + index}`} marker={marker} center={center} />
              );
            })}
          </MarkerClusterGroup>
        </Map>
        <div className={bem({ block, elem: 'legend-status' })}>
          {this.state.incidentStates.map((status, index) => {
            return (
              <span
                title={status.description}
                key={index + status.name}
                className={bem({ block, elem: `status`, mods: this.getStatusMods(status.id) })}
              >
                {status.name}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}
