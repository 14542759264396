/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import { Form } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { find, get, merge } from 'lodash';
import { DropzoneComponent } from '../Dropzone';
import bem from '../../lib/bem';
import './style.css';
import ButtonComponent from '../controls/Button';
import { Map } from './Map';
import { initIncidentTypes } from '../../helpers/dictionaries';
import { ROUTE_APPEALS } from '../../constants/routes';
import context from '../../api/api';
import { Prompt } from 'react-router';
import { formatNumberBox, escapeSingleQuote } from '../../helpers/common';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

loadMessages(ruMessages);
locale('ru-RU');

const block = 'requestForm';

export class RequestPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.coordinates = props.userLocation;
    this.quotesPattern = /'/;
    this.housePattern = /^\d{1,4}(\/\d{1,2})?$/;

    this.state = {
      formDataValues: {
        ...this.initForm()
      },
      files: [],
      formIsDirty: false
    };
  }

  UNSAFE_componentWillMount() {
    document.body.classList.add('request-form-wrapper');
  }

  componentWillUnmount() {
    document.body.classList.remove('request-form-wrapper');
  }

  componentDidUpdate = () => {
    const { formIsDirty } = this.state;
    if (formIsDirty) {
      window.onbeforeunload = e => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  onSubmit = (e) => {
    const { history, address } = this.props;
    const {
      formDataValues: {
        typeOfIncident1,
        typeOfIncident2,
        typeOfIncident3,
        notes,
        municipalityAlias,
        localityFias,
        locality,
        streetFias,
        street,
        house,
        building,
        entrance,
        floor,
        apartment,
        highwayName,
        highwayKm,
        typeOfAddress,
        comment
      },
      files
    } = this.state;

    const typeCode = typeOfIncident3 || typeOfIncident2 || typeOfIncident1;
    // eslint-disable-next-line react/destructuring-assignment
    const typeName = this.props.types.results.find(item => item.code === typeCode).indexName;
    const filePromises = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (res) => {
          const splitSeparator = 'base64,';
          const base64FileBody = res.target.result.split(splitSeparator)[1];
          resolve({
            fileName: file.name,
            fileBody: base64FileBody
          });
        };
      });
    });

    Promise.all(filePromises).then((formattedFiles) => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onSubmitForm({
        typeCode,
        typeName,
        notes,
        files: formattedFiles,
        history,
        address: {
          localityFias,
          locality: locality && locality.localityName,
          streetFias: typeOfAddress != 'street' ? null : streetFias,
          street: typeOfAddress != 'street' ? null : street && street.streetName,
          municipalityAlias,
          house: typeOfAddress != 'street' ? null : house,
          building: typeOfAddress != 'street' ? null : building,
          entrance: typeOfAddress != 'street' ? null : entrance,
          floor: typeOfAddress != 'street' ? null : floor,
          apartment: typeOfAddress != 'street' ? null : apartment,
          highway: typeOfAddress == 'street' ? null : {
            name: highwayName,
            km: highwayKm
          },
          comment
        }
      });
    });

    e.preventDefault();
  };

  onCoordsChanged = (data) => {
    this.coordinates = {
      coords: data
    };
  };

  onFilesChanged = (data) => {
    this.setState({
      files: data,
      formIsDirty: true
    });
  };

  onSelectItemClick = id => (e) => {
    const {
      formDataValues: {
        typeOfIncident1,
        typeOfIncident2,
        typeOfIncident3
      }
    } = this.state;
    const formDataValues = { ...this.state.formDataValues };
    const typesOfIncident = [typeOfIncident1, typeOfIncident2, typeOfIncident3];

    typesOfIncident.forEach((item, index) => {
      if (index + 1 >= Number(id)) {
        formDataValues[`typeOfIncident${index + 1}`] = null;
      }

      formDataValues[`typeOfIncident${id}`] = e.itemData.code;
    });
  };

  getAddressFormItems = () => {
    const {
      formDataValues: {
        municipality,
        isMunicipalityACity, // является ли выбранный муниципалитет городом
        locality, // населенный пункт
        street, // Улица
        house, // Дом
        building, // Корпус
        entrance, // Подъезд
        floor, // Этаж
        apartment, // Квартира
        highwayKm, // Шоссе название 
        highwayName, // Шоссе километр
        typeOfAddress, // тип адреса
        comment // комментарий к адресу
      },
    } = this.state;

    const { municipalities } = this.props;

    const defaultLookup = {
      editorType: 'dxLookup',
      colSpan: 12,
      editorOptions: {
        showCancelButton: false,
        showPopupTitle: false,
        popupHeight: 'auto',
        closeOnOutsideClick: true,
        searchTimeout: 100,
        useNativeScrolling: true,
      },
      label: {
        location: 'top'
      }
    };
    const defaultSelectBox = {
      editorType: 'dxSelectBox',
      colSpan: 12,
      editorOptions: {
        showCancelButton: false,
        showPopupTitle: false,
        closeOnOutsideClick: true,
        searchTimeout: 100,
        useNativeScrolling: true,
        shading: true,
        shadingColor: "rgba(0, 0, 0, 0.2)",
      },
      label: {
        location: 'top'
      }
    };

    const municipalityItem = merge({}, defaultSelectBox, {
      dataField: 'municipalityFias',
      editorOptions: {
        placeholder: 'Выберите муниципальное образование',
        dataSource: municipalities,
        displayExpr: 'portalAlias',
        valueExpr: 'fiasCode',
        searchEnabled: true,
        acceptCustomValue: true,
        onValueChanged: (e) => {
          let selectedItem = e.component._options.selectedItem;

          this.setState(prevState => ({
            ...prevState,
            formDataValues: {
              ...prevState.formDataValues,
              municipality: selectedItem,
              municipalityFias: selectedItem.fiasCode,
              municipalityAlias: selectedItem.alias,
              locality: null,
              street: null,
              isMunicipalityACity: selectedItem.isCity,
              house: null,
              building: null,
              entrance: null,
              floor: null,
              apartment: null,
              highwayName: null,
              highwayKm: null,
              typeOfAddress: selectedItem.isCity ? 'street' : prevState.formDataValues.typeOfAddress
            }
          }));
        },

        value: municipality && municipality.fiasCode,
      },
      label: {
        text: 'Муниципальное образование',
      },
      validationRules: [{
        type: 'required',
        message: 'Это обязательное поле'
      }]
    });

    const typeOfAddressItem = {
      editorType: 'dxRadioGroup',
      dataField: 'typeOfAddress',
      colSpan: 12,
      editorOptions: {
        items: [
          { value: 'street', name: 'Населенный пункт' },
          { value: 'highway', name: 'Шоссе' }
        ],
        valueExpr: 'value',
        displayExpr: 'name',
        layout: 'horizontal',
        onValueChanged: (e) => {
          this.setState(prevState => ({
            ...prevState,
            formDataValues: {
              ...prevState.formDataValues,
              typeOfAddress: e.value,
            }
          }))
        }
      },
      label: {
        text: 'Тип адреса'
      },
      disabled: isMunicipalityACity
    };

    const localityItem = merge({}, defaultSelectBox, {
      dataField: 'localityFias',
      editorOptions: {
        placeholder: 'Введите населенный пункт',
        dataSource: new CustomStore({
          key: 'localityFiasCode',
          load: p => context.address.findLocality(municipality && municipality.fiasCode, p.searchValue),
          byKey: key => (locality && locality.localityFiasCode === key ? locality : null)
        }),
        displayExpr: 'localitySuggestion',
        valueExpr: 'localityFiasCode',
        acceptCustomValue: true,
        searchEnabled: true,
        onItemClick: e => this.setState(prevState => ({
          ...prevState,
          formDataValues: {
            ...prevState.formDataValues,
            locality: e.itemData,
            localityFias: e.itemData.localityFiasCode,
            street: null,
            highwayKm: null,
            highwayName: null
          }
        })),
        value: locality && locality.localityFiasCode
      },
      disabled: !municipality || isMunicipalityACity,
      label: {
        text: 'Населенный пункт',
      },
      visible: typeOfAddress == 'street'
    });

    const streetItem = merge({}, defaultSelectBox, {
      dataField: 'streetFias',
      editorOptions: {
        placeholder: 'Введите улицу',
        dataSource: new CustomStore({
          key: 'streetFiasCode',
          load: p => context.address.findStreet(municipality && municipality.fiasCode, locality || {}, p.searchValue),
          byKey: key => (street && street.streetFiasCode === key ? street : null)
        }),
        displayExpr: 'streetSuggestion',
        valueExpr: 'streetFiasCode',
        acceptCustomValue: true,
        searchEnabled: true,
        onItemClick: e => this.setState(prevState => ({
          ...prevState,
          formDataValues: {
            ...prevState.formDataValues,
            street: e.itemData,
            streetFias: e.itemData.streetFiasCode
          }
        })),
        value: street && street.streetFiasCode
      },
      disabled: !locality && !isMunicipalityACity,
      label: {
        text: 'Улица',
      },
      visible: typeOfAddress == 'street'
    });
    const houseItem = {
      editorType: 'dxTextBox',
      dataField: 'house',
      colSpan: 8,
      cssClass: 'house',
      editorOptions: {
        placeholder: 'Номер дома',
        value: house,
      },
      disabled: !street,
      label: {
        text: 'Дом',
        location: 'top'
      },
      visible: typeOfAddress == 'street'
    };
    const buildingItem = {
      editorType: 'dxTextBox',
      dataField: 'building',
      colSpan: 4,
      cssClass: 'building',
      editorOptions: {
        placeholder: 'Корпус',
        value: building,
      },
      disabled: !street,
      label: {
        text: 'Корпус',
        location: 'top'
      },
      visible: typeOfAddress == 'street'
    };
    const entranceItem = {
      editorType: 'dxNumberBox',
      dataField: 'entrance',
      colSpan: 4,
      cssClass: 'entrance',
      editorOptions: {
        placeholder: 'Подъезд',
        value: formatNumberBox(entrance),
      },
      disabled: !street,
      label: {
        text: 'Подъезд',
        location: 'top'
      },
      visible: typeOfAddress == 'street'
    };
    const floorItem = {
      editorType: 'dxNumberBox',
      dataField: 'floor',
      colSpan: 4,
      cssClass: 'floor',
      editorOptions: {
        placeholder: 'Этаж',
        value: formatNumberBox(floor),
      },
      disabled: !street,
      label: {
        text: 'Этаж',
        location: 'top'
      },
      visible: typeOfAddress == 'street'
    };
    const apartmentItem = {
      editorType: 'dxNumberBox',
      dataField: 'apartment',
      colSpan: 4,
      cssClass: 'apartment',
      editorOptions: {
        placeholder: 'Квартира',
        value: formatNumberBox(apartment),
      },
      disabled: !street,
      label: {
        text: 'Квартира',
        location: 'top'
      },
      visible: typeOfAddress == 'street'
    };
    const highwayNameItem = {
      editorType: 'dxTextBox',
      dataField: 'highwayName',
      colSpan: 6,
      cssClass: 'highway-name',
      editorOptions: {
        placeholder: 'Шоссе (трасса)'
      },
      label: {
        text: 'Шоссе (трасса)',
        location: 'top'
      },
      visible: typeOfAddress == 'highway'
    };
    const highwayKmItem = {
      editorType: 'dxNumberBox',
      dataField: 'highwayKm',
      colSpan: 6,
      cssClass: 'highway-km',
      editorOptions: {
        placeholder: 'Километр',
        value: formatNumberBox(highwayKm),
      },
      label: {
        text: 'Километр',
        location: 'top'
      },
      visible: typeOfAddress == 'highway'
    };
    const commentItem = {
      editorType: 'dxTextArea',
      dataField: 'comment',
      colSpan: 12,
      cssClass: 'comment',
      editorOptions: {
        maxLength: 255,
        height: 83,
        placeholder: 'Введите дополнительную информацию о местоположении',
        onInput: x => this.handleKeyUp(x, 'commentCharCount')
      },
      label: {
        text: 'Дополнительная информация о местоположении',
        location: 'top'
      },
      validationRules: [{
        type: 'stringLength',
        max: 255,
        message: 'Не более 255 символов'
      }, {
        type: 'custom',
        validationCallback: this.validationCallback,
        message: 'Был введен недопустимый символ - одинарные кавычки',
        reevaluate: true
      }]
    };
    const commentCharCountItem = {
      dataField: 'commentCharCount',
      editorType: 'dxTextArea',
      colSpan: 12,
      cssClass: 'notes-counter',
      editorOptions: {
        height: 20,
        width: '100%'
      },
      label: {
        visible: false,
        text: ''
      },
      disabled: true
    };

    return [
      municipalityItem,
      typeOfAddressItem,
      localityItem,
      streetItem,
      houseItem,
      buildingItem,
      entranceItem,
      floorItem,
      apartmentItem,
      highwayNameItem,
      highwayKmItem,
      commentItem,
      commentCharCountItem
    ];
  }

  getFormItems = () => {
    return [
      {
        itemType: 'group',
        caption: '',
        items: this.editIndexFormItems()
      },
      {
        itemType: 'group',
        caption: 'Место события:',
        items: this.getAddressFormItems(),
        colCount: 12
      },
      {
        itemType: 'group',
        caption: 'Сопроводительный текст:',
        cssClass: 'np',
        items: this.getAdditionalFormItems()
      }
    ];
  };

  handleKeyUp = (e, fieldName) => {
    let currentText = e.event.target.value;
    const maxLength = e.event.target.maxLength;

    let splittedBySpace = fieldName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    let dataFieldName = splittedBySpace.substring(0, splittedBySpace.indexOf(' '));

    if (this.quotesPattern.test(currentText) == true) {
      this.form.updateData(dataFieldName, escapeSingleQuote(currentText));
    }

    const typedCharCount = e.event.target.value.length;
    const remainder = (maxLength - typedCharCount).toString();
    this.form.updateData(
      fieldName,
      'Максимальное допустимое количество символов в данном поле: ' + maxLength + ' (осталось - ' + remainder + ')');
  }

  validationCallback = (e) => {
    return !this.quotesPattern.test(e.value);
  }

  // onValueChanged = (e) => {
  //   this.form.updateData('notes', escapeSingleQuote(e.value));
  // }

  getAdditionalFormItems = () => {

    const notesItem = {
      dataField: 'notes',
      editorType: 'dxTextArea',
      editorOptions: {
        height: 83,
        required: true,
        placeholder: 'Введите краткое описание происшествия',
        maxLength: 255,
        onInput: x => this.handleKeyUp(x, 'notesCharCount'),
        // onValueChanged: this.onValueChanged
      },
      label: {
        location: 'top',
        text: 'Описание',
        visible: true,
      },
      validationRules: [{
        type: 'required',
        message: 'Это обязательное поле'
      }, {
        type: 'stringLength',
        max: 255,
        message: 'Не более 255 символов'
      }, {
        type: 'custom',
        validationCallback: this.validationCallback,
        message: 'Был введен недопустимый символ - одинарные кавычки',
        reevaluate: true
      }]
    };

    const notesCharCountItem = {
      dataField: 'notesCharCount',
      editorType: 'dxTextArea',
      cssClass: 'notes-counter',
      editorOptions: {
        height: 20,
        width: '100%'
      },
      label: {
        visible: false,
        text: ''
      },
      disabled: true
    };

    return (
      [notesItem, notesCharCountItem]
    );
  }

  initForm = () => {
    return {
      typeOfIncident1: null,
      typeOfIncident2: null,
      typeOfIncident3: null,
      notes: '',
      typeOfAddress: 'street'
    };
  };

  editIndexFormItems = () => {
    const {
      formDataValues
    } = this.state;
    const selectTypes = this.props.types.results;

    const firstLevelTypes = initIncidentTypes(selectTypes);
    const dataSource = [firstLevelTypes];

    return dataSource.map((data, index) => {
      const level = index + 1;
      const validation = level === 1
        ? {
          validationRules: [{
            type: 'required',
            message: 'Это обязательное поле'
          }]
        }
        : {};

      return {
        dataField: `typeOfIncident${level}`,
        editorType: 'dxLookup',
        editorOptions: {
          placeholder: 'Выберите тип происшествия',
          dataSource: data,
          displayExpr: 'indexName',
          valueExpr: 'code',
          showCancelButton: false,
          showPopupTitle: false,
          popupHeight() {
            return window.innerHeight / 3;
          },
          closeOnOutsideClick: true,
          searchTimeout: 100,
          onItemClick: this.onSelectItemClick(level),
          value: formDataValues[`typesOfIncident${level}`],
          useNativeScrolling: true,
        },
        disabled: level === 1 ? false : !formDataValues[`typeOfIncident${level - 1}`],
        label: {
          text: 'Тип происшествия',
          location: 'top'
        },
        ...validation
      };
    });
  };

  formRef = (node) => {
    return this.form = node ? node.instance : null;
  };

  getMimeTypes = () => {
    return 'image/*, video/*, text/*, .pdf, .doc, .docx, .xls, .xlsx';
  }

  dropzoneRef = node => this.dropzone = node;

  render() {
    const {
      isAppealSending,
    } = this.props;
    const { formDataValues, formIsDirty } = this.state;
    console.warn(formDataValues);

    return (
      <React.Fragment>
        <Prompt
          when={formIsDirty}
          message="На странице имеются несохраненные данные, которые будут потеряны. Вы хотите завершить работу со страницей?"
        />
        <Grid fluid>
          <Grid className={bem({ block })}>
            <form onSubmit={this.onSubmit}>
              <Form
                ref={this.formRef}
                validationGroup={block}
                formData={formDataValues}
                colCount="auto"
                colCountByScreen={{
                  md: 1,
                  sm: 1
                }}
                screenByWidth={(width) => {
                  return width < 720 ? 'sm' : 'md';
                }}
                showRequiredMark
                items={this.getFormItems()}
                onFieldDataChanged={(e) => { if (e.value !== undefined) this.setState({ formIsDirty: true }); }}

              />
              <p className="dx-form-group-caption">Приложенные файлы</p>
              <DropzoneComponent
                ref={this.dropzoneRef}
                wrapperClassName={bem({ block, elem: 'dropzone-wrapper' })}
                className={bem({ block, elem: 'dropzone' })}
                activeClassName={bem({ block, elem: 'dropzone-active' })}
                maxSize={25 * 1024 * 1024}
                maxCount={4}
                onFilesChanged={this.onFilesChanged}
                accept={this.getMimeTypes()}
                acceptedFileLength={64}
              >
                <div className={bem({ block, elem: 'summary' })}>
                  <p className={bem({ block, elem: 'dropzone-head' })}>Перетащите файлы сюда</p>
                  <p className={bem({ block, elem: 'dropzone-attention' })}>Загрузить можно не больше 4х файлов общим размером 25мб</p>
                </div>
              </DropzoneComponent>
              <p className={bem({ block, elem: 'note-hint' })}>
                <span className="dx-field-item-required-mark">*</span> - Данные поля обязательны для заполнения
              </p>
              <div>
                <ButtonComponent
                  height={50}
                  text="Создать обращение"
                  type="success"
                  className={bem({ block, elem: 'button-submit' })}
                  validationGroup={block}
                  disabled={isAppealSending}
                  useSubmitBehavior
                  onClick={() => { this.setState({ formIsDirty: false }); }}
                />
                <Link to={ROUTE_APPEALS}>
                  <ButtonComponent
                    height={50}
                    text="Отменить создание обращения"
                    type="normal"
                    className={bem({ block, elem: 'button-cancel' })}
                    validationGroup={block}
                    onClick={() => { this.setState({ formIsDirty: false }); }}
                  />
                </Link>
              </div>
            </form>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
