import { fromJS } from 'immutable';
import { ABOUT_PAGE_DATA_LOADED } from './constants';

export const storeName = 'aboutPage';

const aboutPageDataLoaded = (state, { aboutPageData }) => {  
  return state
    .set('aboutPageData', aboutPageData);
};
const initialState = fromJS({
  aboutPageData: {}
});

export default {
  [storeName]: (state = initialState, action) => {
    switch (action.type) {
      case ABOUT_PAGE_DATA_LOADED:
        return aboutPageDataLoaded(state, action.payload);
      default:
        return state;
    }
  }
};
