import { fromJS, setIn } from 'immutable';
import {
  REMINDERS_CONTENT_LOADED,
  REMINDER_LIST_LOADED,
  SET_REMINDER_NODE_EXPANDED,
  SET_REMINDER_NODE_COLLAPSED,
  REMINDER_SECTIONS_LOADED,
  REMINDER_SECTION_DOCUMENTS_LOADED
} from './constants';

export const storeName = 'reminders';

const remindersContentLoaded = (state, payload) => {
  return state.set('remindersData', payload);
};

const reminderListLoaded = (state, payload) => {
  return state.set('reminderList', payload);
};

const setReminderNodeExpanded = (state, id, value) => {
  return state.setIn(['expandedNodes', id], value);
};

const remindersSectionsLoaded = (state, payload) => {
  return state.set('remindersSections', payload);
}

const remindersSectionDocumentsLoaded = (state, payload) => {
  return state.set('remindersSectionDocuments', payload);
}

const initialState = fromJS({
  remindersData: null,
  reminderList: [],
  expandedNodes: {},
  remindersSections: [],
  remindersSectionDocuments: [],
});

export default {
  [storeName]: (state = initialState, action) => {
    switch (action.type) {
      case REMINDERS_CONTENT_LOADED:
        return remindersContentLoaded(state, action.payload);
      case REMINDER_LIST_LOADED:
        return reminderListLoaded(state, action.payload);
      case SET_REMINDER_NODE_EXPANDED:
        return setReminderNodeExpanded(state, action.payload, true);
      case SET_REMINDER_NODE_COLLAPSED:
        return setReminderNodeExpanded(state, action.payload, false);
      case REMINDER_SECTIONS_LOADED:
        return remindersSectionsLoaded(state, action.payload);
      case REMINDER_SECTION_DOCUMENTS_LOADED:
        return remindersSectionDocumentsLoaded(state, action.payload);
      default:
        return state;
    }
  }
};
