import { GET_ABOUT_PAGE_DATA, ABOUT_PAGE_DATA_LOADED, ABOUT_PAGE_DATA_LOAD_FAILED } from './constants';

export const getAboutPageData = () => {
  return {
    type: GET_ABOUT_PAGE_DATA
  };
};

export const aboutPageDataLoaded = (aboutPageData) => {
  return {
    type: ABOUT_PAGE_DATA_LOADED,
    payload: { aboutPageData }
  };
};

export const aboutPageDataLoadFailed = (error) => {
  return {
    type: ABOUT_PAGE_DATA_LOAD_FAILED,
    payload: { error }
  };
};