import React from 'react';
import { LoadIndicator } from 'devextreme-react';
import bem from '../../lib/bem';
import './style.css';

const block = 'global-loader';

export class GlobalLoader extends React.PureComponent {
  render() {
    return (
      <div className={bem({ block })}>
        <div className={bem({ block, elem: 'loader-table' })}>
          <div className={bem({ block, elem: 'loader-row' })}>
            <div className={bem({ block, elem: 'loader-cell' })}>
              <LoadIndicator width="70" height="70" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}