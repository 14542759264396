import React from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import bem from '../../../lib/bem';
import { Link } from 'react-router-dom';
import { ROUTE_ABOUT, ROUTE_FAQ, ROUTE_REMINDERS } from '../../../constants/routes';
import './style.css';

const block = 'footer';

export default class Footer extends React.PureComponent {

  getFooterContent = () => {
    const { userLogin, isUserAuthenticated, combinedFooterData, footerData } = this.props;

    return footerData.map(({ id, title, elements }) => {
      return (
        <Col xs={6} md={3} key={id}>
          <div className={bem({ block, elem: 'block' })}>
            <span className={bem({ block, elem: 'item-title' })}>
              {title}
            </span>
            {elements.map(({ id: itemId, name, link }) => {

              if (id === 1) {
                switch (itemId) {
                  case 1:
                    return (
                      <a
                        className={bem({ block, elem: `item ${isUserAuthenticated ? ' disabled' : ''}` })}
                        key={itemId + name}
                        onClick={() => userLogin()}>
                        {name}
                      </a>
                    );
                  case 2:
                    return (
                      <Link
                        className={bem({ block, elem: `item` })}
                        key={itemId + name}
                        to={ROUTE_FAQ}>
                        {name}
                      </Link>
                    );
                }
              }
              else if (id === 2) {
                return (
                  <Link
                    className={bem({ block, elem: `item` })}
                    key={itemId}
                    to={ROUTE_REMINDERS + link}>
                    {name}
                  </Link>
                );
              }
              else if (id === 3 && itemId == 1) {
                return (
                  <Link
                    className={bem({ block, elem: 'item' })}
                    key={itemId + name}
                    to={ROUTE_ABOUT}
                  >
                    {name}
                  </Link>
                );
              } else if (id === 4) {
                return (
                  <a
                    className={bem({ block, elem: 'item' })}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={itemId}
                    href={link && link}
                  >
                    {name}
                  </a>
                );
              }

              // switch (id) {
              //   case 1:
              //     if (itemId === 1) { // ссылка на авторизацию
              //       return (
              //         <a
              //           className={bem({ block, elem: `item ${isUserAuthenticated ? ' disabled' : ''}` })}
              //           key={itemId + name}
              //           onClick={() => userLogin()}>
              //           {name}
              //         </a>
              //       );
              //     } else if (itemId === 2) { // ссылка на faq
              //       return (
              //         <Link
              //           className={bem({ block, elem: `item` })}
              //           key={itemId + name}
              //           to={ROUTE_FAQ}>
              //           {name}
              //         </Link>
              //       );
              //     }
              //   case 2:
              //     footerReminderSections.map((section, i) => {
              //       console.log(section.Id)
              //       return (
              //         <Link
              //           className={bem({ block, elem: 'item' })}
              //           key={section.id}
              //           to={ROUTE_REMINDERS}
              //         >
              //           {section.Id}
              //         </Link>
              //       );
              //     });

              //   case 3:
              //     if (itemId === 1) { // ссылка на страницу about
              //       return (
              //         <Link
              //           className={bem({ block, elem: 'item' })}
              //           key={itemId + name}
              //           to={ROUTE_ABOUT}
              //         >
              //           {name}
              //         </Link>
              //       );
              //     }
              //   case 4: // ссылки на внешние ресурсы
              //     return (
              //       <a
              //         className={bem({ block, elem: 'item' })}
              //         target="_blank"
              //         rel="noopener noreferrer"
              //         key={itemId}
              //         href={link && link}
              //       >
              //         {name}
              //       </a>
              //     );

              //   // default:
              //   //   return (
              //   //     <a
              //   //       className={bem({ block, elem: 'item' })}
              //   //       key={itemId}
              //   //       href={link && link}
              //   //     >
              //   //       {name}
              //   //     </a>
              //   //   );
              // }
            })}
          </div>
        </Col>
      );
    });
  };

  shouldComponentRender = () => {
    const { footerData } = this.props;

    return footerData && !!footerData.length;
  };

  render() {


    if (!this.shouldComponentRender()) {
      return <div />;
    }

    return (
      <footer className={bem({ block })}>
        <Grid>
          <Row>
            {this.getFooterContent()}
            {/* {this.getRemindersContent()} */}
          </Row>
        </Grid>
      </footer>
    );
  }
}
