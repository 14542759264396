import { fromJS } from 'immutable';
import { FOOTER_DATA_LOADED } from './constants';
import { REMINDER_SECTIONS_LOADED } from '../RemindersPage/constants';

export const storeName = 'footer';

const footerDataLoaded = (state, { footerData }) => {
  return state
    .set('footerData', footerData);
};

const remindersSectionsLoaded = (state, payload) => {

  const elements = [];
  payload.forEach(el => {
    elements.push({ id: el.Id, name: el.Name, link: '?id=' + el.Id });
  });
  const footerList = state.get('footerData');
  footerList.map((x) => {
    if (x.id === 2) {
      x.elements = elements;
    }
  });

  return state
    .set('footerData', footerList)
};

const initialState = fromJS({
  footerData: [],
  footerReminderSections: [],
  combinedFooterData: []
});

export default {
  [storeName]: (state = initialState, action) => {
    switch (action.type) {
      case FOOTER_DATA_LOADED:
        return footerDataLoaded(state, action.payload);
      case REMINDER_SECTIONS_LOADED:
        return remindersSectionsLoaded(state, action.payload);
      default:
        return state;
    }
  }
};
