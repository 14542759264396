import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { divIcon } from 'leaflet';
import moment from 'moment';
import bem from '../../../lib/bem';
import { STATUS_DONE, STATUS_NEW, STATUS_WORK } from '../../../constants/statuses';
import emergencyIcon from '../../../images/emergency.svg';
import { Modal, ModalHeader, ModalTitle, ModalBody } from 'react-bootstrap';
import { IncidentModal } from '../../modals/IncidentModal';

const block = 'leaflet-map';

export class MapMarker extends React.PureComponent {

  state = {
    visible: false
  }

  getMarkerMods = () => {
    const {
      marker: {
        isEmergency, isDanger, isPotentialDanger
      }
    } = this.props;
    return { emergency: isEmergency || isDanger || isPotentialDanger, status: this.getStatusClass() };
  };

  getStatusClass = () => {
    const {
      marker: {
        stateId,
      }
    } = this.props;

    switch (stateId) {
      case STATUS_DONE:
        return 'done';
      case STATUS_NEW:
        return 'new';
      case STATUS_WORK:
        return 'work';
      default:
        return '';
    }
  };

  getMarkerIcon = () => {
    return divIcon({
      html: `<i class="${bem({ block, elem: 'leaflet-custom-marker', mods: this.getMarkerMods() })}" />`
    });
  };

  openModal = () => {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  renderTitleModal = () => {
    return (
      <div className={bem({ block, elem: 'modal-title-block' })}>
        <div className={bem({ block, elem: 'modal-title' })}>
          Карточка происшествия
        </div>
      </div>
    );
  }

  renderBodyContent = () => {
    const { marker } = this.props;
    return (
      <IncidentModal incident={marker}></IncidentModal>
    )
  }

  render() {
    const {
      marker: {
        latitude,
        longitude,
        stateName,
        serviceTypeName,
        shortAddress,
        fullAddress,
        created,
        cardIndexName,
        isDanger,
        isPotentialDanger
      },
      center,
    } = this.props;

    const { visible } = this.state;

    return (
      <Marker position={[latitude || center[0], longitude || center[1]]} icon={this.getMarkerIcon()}>
        <Popup>
          <div className={bem({ block })} onClick={this.openModal}>
            <div>
              <span className={bem({ block, elem: 'title' })}>{serviceTypeName}</span>
              &nbsp;{(isDanger || isPotentialDanger) ? (<img src={emergencyIcon} width="15" height="15" alt="ЧС" />) : ''}
              <span className={bem({ block, elem: `status ${this.getStatusClass()}` })}>{stateName}</span>
            </div>
            <div className={bem({ block, elem: 'reason' })}>
              <span>{cardIndexName}</span>
            </div>
            <div className={bem({ block, elem: 'date' })}>
              <i className="fa fa-clock-o" />
              <span>Дата и время регистрации:</span> {moment(created).format('DD.MM.YYYY HH:mm')}
            </div>
            <div className={bem({ block, elem: 'address' })}>
              <i className="fa fa-map-marker" />
              <span>Адрес:</span> {fullAddress}
            </div>
          </div>
          <Modal show={visible} bsSize="lg" effect="fadeInUp" onHide={() => this.closeModal()}>
            <ModalHeader closeButton>
              <ModalTitle className={bem({ block, elem: 'title' })}>
                {this.renderTitleModal()}
              </ModalTitle>
            </ModalHeader>
            <ModalBody className={bem({ block, elem: 'body' })}>
              {this.renderBodyContent()}
            </ModalBody>
          </Modal>
        </Popup>
      </Marker>
    );
  }
}
