import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import orderBy from 'lodash/orderBy';
import { fileSizeSI, fileSizeIEC } from '../../helpers/common';
import './style.css';
import { FILE_NAME_LENGTH_ERROR_MESSAGE, FILES_SIZE_ERROR_MESSAGE, FILES_COUNT_ERROR_MESSAGE } from '../../constants/common';

export class DropzoneComponent extends React.PureComponent {
  static defaultProps = {
    wrapperClassName: 'dropzone-wrapper',
    className: 'dropzone',
    activeClassName: 'dropzone-active',
    acceptClassName: 'dropzone-accept',
    maxSize: 10 * 1024,
    maxCount: 10,
    accept: '' // все файлы
  };

  state = {
    accepted: [],
    rejected: [],
    error: '',
    countError: '',
    fileNameLengthError: ''
  };

  // проверка файлов
  // файлы большего размера до сюда не доходят
  onDrop = (accepted, rejected) => {
    const { acceptedFileLength } = this.props;
    let acceptedsize = 0;
    let error = '', countError = '', fileNameLengthError = '';

    // предыдущие файлы не исчезают при повторном добавлении
    let tempAccepted = [...this.state.accepted, ...accepted];
    let tempRejected = [...this.state.rejected, ...rejected];

    tempAccepted = orderBy(tempAccepted, ['size'], ['desc']);

    for (let i = 0; i < tempAccepted.length; i++) {
      const file = tempAccepted[i];
      if (acceptedsize + file.size > this.props.maxSize && file.name.length <= acceptedFileLength) {
        // file.errorMessage = 'Размер файлов превышает допустимое ограничение';
        file.isOverSize = true;
        error += FILES_SIZE_ERROR_MESSAGE;
        let sliced = tempAccepted.slice(i, i + 1)[0];
        tempRejected.push(sliced);
        tempAccepted.splice(i, 1); // удаление одного
        i -= 1;
      } else {
        file.isOverSize = false;
        acceptedsize += file.size;
      }
    }

    for (let i = 0; i < tempAccepted.length; i++) {
      const file = tempAccepted[i];
      if (file.name.length > acceptedFileLength) {
        // file.errorMessage = 'Вы пытаетесь загрузить файл с длинным именем, сократите название и попробуйте снова';
        file.hasTooLongName = true;
        fileNameLengthError = FILE_NAME_LENGTH_ERROR_MESSAGE;
        tempRejected.push(tempAccepted.slice(i, i + 1)[0]);
        tempAccepted.splice(i, 1); // удаление одного
        i = -1;
      } else {
        file.hasTooLongName = false;
      }
    }

    tempAccepted.forEach((file, i) => {
      if ((i + 1) > this.props.maxCount) {
        countError = FILES_COUNT_ERROR_MESSAGE;
        let tempAcceptedLength = tempAccepted.length;
        tempRejected = [...tempRejected, ...tempAccepted.splice(i, tempAcceptedLength - i)];
      }
    });

    this.setState({
      accepted: tempAccepted,
      rejected: tempRejected.filter(x => x != undefined),
      error,
      countError,
      fileNameLengthError
    });

    this.props.onFilesChanged(tempAccepted);
  };

  // очистка локально
  clearDropzone = () => {
    this.setState({
      accepted: [],
      rejected: [],
      error: '',
      countError: '',
      fileNameLengthError: '',
    });
  };

  deleteFileFromAccepted = (fileName) => {
    const acceptedFiles = [...this.state.accepted];

    let fileIndex = null;
    this.state.accepted.forEach((file, index) => {
      if (file.name === fileName) {
        fileIndex = index;
      }
    });
    acceptedFiles.splice(fileIndex, 1);

    this.setState(
      { accepted: [...acceptedFiles] },
      () => {
        const { accepted } = this.state;
        this.props.onFilesChanged(accepted);
      }
    );
  };

  deleteFileFromRejected = (fileName) => {
    const { acceptedFileLength } = this.props;
    const rejectedFiles = [...this.state.rejected];

    let fileIndex = null;
    this.state.rejected.forEach((file, index) => {
      if (file.name === fileName) {
        fileIndex = index;
      }
    });
    rejectedFiles.splice(fileIndex, 1);
    this.setState({ rejected: [...rejectedFiles] });
    if (
      rejectedFiles.filter(x => !x.hasTooLongName && !x.isOverSize).length === 0) {
      this.setState({ countError: '' })
    }
    if (
      rejectedFiles.filter(x => x.isOverSize).length === 0) {
      this.setState({ error: '' })
    }
    if (
      rejectedFiles.filter(x => x.hasTooLongName && !x.isOverSize).length === 0) {
      this.setState({ fileNameLengthError: '' });
    }
    if (rejectedFiles.length === 0) {
      this.setState({ error: '', countError: '', fileNameLengthError: '' });
    }
  };

  formatBytes = (bytes, decimals) => {
    if (bytes === 0) return '0 Мб';
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Байт', 'Кб', 'Мб'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const decimalKbSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    const kbSize = `${decimalKbSize}${sizes[i]}`;
    const mbSize = `${(decimalKbSize / 1024)}`;

    if (bytes > Math.pow(1024, 2))
      return fileSizeSI(bytes);

    return `${Math.round(mbSize * 100) / 100}Мб (${kbSize})`;
  }

  render() {
    const {
      className,
      activeClassName,
      acceptClassName,
      maxSize,
      children,
      accept,
      wrapperClassName,
    } = this.props;

    const { accepted, rejected, error, countError, fileNameLengthError } = this.state;

    return (
      <div className={wrapperClassName}>
        {
          accepted.length
            ? (
              <Fragment>
                <p className="title">Принятые:</p>
                <table className="files-table">
                  <tbody>
                    {accepted && accepted.map(file => (
                      <tr key={file.name} className="files-table-row">
                        <td className="icon-cell">
                          <span className="icon-file" />
                        </td>
                        <td className="name-cell">{file.name}</td>
                        <td className="size-cell">{this.formatBytes(file.size)}</td>
                        <td className="action-cell">
                          <div>
                            <span className="icon icon-delete" data-tip="Удалить" onClick={() => this.deleteFileFromAccepted(file.name)} />
                          </div>
                        </td>
                      </tr>)
                    )}
                  </tbody>
                </table>
              </Fragment>
            )
            : ''
        }
        {
          rejected.length ? (
            <Fragment>
              <p className="title-rejected">Отклоненные:</p>
              <div className="rejected-files-block">
                <table className="files-table files-rejected">
                  <tbody>
                    {rejected && rejected.map((file, i) => (
                      <tr key={i} className="files-table-row">
                        <td className="icon-cell">
                          <span className="icon-file" />
                        </td>
                        <td className="name-cell">{file.name}
                          {/* <p className="error-message">{file.errorMessage}</p> */}
                        </td>
                        <td className="size-cell">
                          <p className={file.isOverSize ? 'error-message error-size' : ''}>{this.formatBytes(file.size)} </p>
                          <p className="error-message">{file.hasTooLongName && <span>Количество символов в&nbsp;имени - {file.name.length}</span>}</p>
                        </td>
                        <td className="action-cell">
                          <div>
                            <span className="icon icon-delete" title="Удалить" onClick={() => this.deleteFileFromRejected(file.name)} />
                          </div>
                        </td>
                      </tr>)
                    )}
                  </tbody>
                </table>
              </div>
            </Fragment>
          ) : ''
        }
        <Dropzone
          className={className}
          activeClassName={activeClassName}
          acceptClassName={acceptClassName}
          onDrop={this.onDrop}
          maxSize={maxSize}
          accept={accept}
        >
          {children}
          {
            (error || countError || fileNameLengthError) ? (
              <Fragment>
                <p className="errors">
                  {error}
                </p>
                <p className="errors">
                  {countError}
                </p>
                <p className="errors">
                  {fileNameLengthError}
                </p>
              </Fragment>
            ) : ''
          }
        </Dropzone>
      </div>
    );
  }
}
