import { GET_FAQ_DATA, FAQ_DATA_LOADED } from './constants';

export const getFaqData = () => {
  return {
    type: GET_FAQ_DATA
  };
};

export const faqDataLoaded = (faqData) => {
  return {
    type: FAQ_DATA_LOADED,
    payload: { faqData }
  };
};