import React from 'react';
import { IncidentsJournal } from '../../components/IncidentsJournal';
import { EmergencyCell } from '../../components/customTableCells/EmergencyCell';
import { DateCreatedCell } from '../../components/customTableCells/DateCreatedCell';
import { StatusCellWithEmergency } from '../../components/customTableCells/StatusCellWithEmergency';
import { PageTitleComponent } from '../../components/PageTitleComponent';

function getPotentialDanger(rowData) {
  return rowData.isPotentialDanger;
}

function getDanger(rowData) {
  return rowData.isDanger;
}

const columns = [{
  dataField: 'stateName',
  caption: 'Статус',
  dataType: 'string',
  width: '200',
  cellComponent: StatusCellWithEmergency,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: true,
  allowFiltering: true,
}, {
  dataField: 'isDanger',
  caption: 'ЧС',
  dataType: 'string',
  width: '5%',
  cellComponent: EmergencyCell,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: true,
  allowFiltering: false,
  // editorOptions: { showClearButton: true, format: "boolean", },
  calculateFilterExpression: function (value, selectedFilterOperations, target) {
    if (target === "headerFilter" && value === "Признаки ЧС") {
      return [getPotentialDanger, "=", true];
    }
    if (target === "headerFilter" && value === "ЧС") {
      return [getDanger, "=", true];
    }
    if (target === "headerFilter" && value === "Без категории") {
      return [[getPotentialDanger, "=", false], "and", [getDanger, "=", false]];
    }
    return this.defaultCalculateFilterExpression.apply(this, arguments);
  },
  headerFilter: {
    dataSource: function (data) {
      data.dataSource.postProcess = function (results) {
        results = [];
        results.unshift({
          text: "ЧС",
          value: "ЧС"
        });
        results.unshift({
          text: "Признаки ЧС",
          value: "Признаки ЧС"
        });
        results.push({
          text: "Без категории",
          value: "Без категории"
        });
        return results;
      };
    }
  }
}, {
  dataField: 'serviceTypeName',
  caption: 'Тип происшествия',
  dataType: 'string',
  width: '15%',
  cellComponent: null,
  allowSorting: false,
  cssClass: 'bold',
  allowHeaderFiltering: false,
  allowFiltering: true,
}, {
  dataField: 'created',
  caption: 'Дата и время регистрации',
  dataType: 'datetime',
  width: '18%',
  cellComponent: DateCreatedCell,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: true,
  allowFiltering: true,
}, {
  dataField: 'fullAddress',
  caption: 'Адрес происшествия',
  dataType: 'string',
  width: '40%',
  cellComponent: null,
  allowSorting: false,
  cssClass: 'bold',
  allowHeaderFiltering: true,
  allowFiltering: true,
  hidingPriority: 0
}, {
  dataField: 'isPotentialDanger',
  dataType: 'string',
  visible: false
}];
const defaultTotalDataNumber = 0;


export default class IncidentsLogJournal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  onContentReady = (e) => {
    if (!this.state.collapsed) {
      e.component.expandRow(['EnviroCare']);
      this.setState({
        collapsed: true
      });
    }
  };

  render() {
    const { incidents } = this.props;

    return (
      <React.Fragment>
        <PageTitleComponent title='Происшествия' />
        <IncidentsJournal
          onContentReady={this.onContentReady}
          columnsData={incidents.data || []}
          columns={columns}
          totalDataNumber={(incidents && incidents.count) || defaultTotalDataNumber}
        />
      </React.Fragment>
    );
  }
}
