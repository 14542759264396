import React from 'react';
import {Helmet} from "react-helmet";

export class PageTitleComponent extends React.PureComponent{
    render(){
        const {title} = this.props;
        const text = title ? `${title} | Безопасный город | ЛК Гражданина` : 'Безопасный город | ЛК Гражданина';
        return (
            <>
              <Helmet>
                <title>{text}</title>
              </Helmet>
            </>
          )
    }
}