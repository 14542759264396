import { FETCH_TYPES, FETCH_TYPES_ERROR, FETCH_TYPES_SUCCESS, FETCH_INCIDENT_TYPES, FETCH_INCIDENT_TYPES_SUCCESS, FETCH_INCIDENT_TYPES_ERROR } from './constants';

export const fetchTypes = () => {
  return { type: FETCH_TYPES };
};

export const fetchTypesSuccess = (data) => {
  return {
    type: FETCH_TYPES_SUCCESS,
    payload: data
  };
};

export const fetchTypesError = () => {
  return { type: FETCH_TYPES_ERROR };
};


export const fetchIncidentTypes = () => {
  return { type: FETCH_INCIDENT_TYPES };
};

export const fetchIncidentTypesSuccess = (data) => {
  return {
    type: FETCH_INCIDENT_TYPES_SUCCESS,
    payload: data
  };
};

export const fetchIncidentTypesError = () => {
  return { type: FETCH_INCIDENT_TYPES_ERROR };
};
