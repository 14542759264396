import { put, call, takeEvery } from 'redux-saga/effects';
import { 
  fetchTypesSuccess, 
  fetchTypesError, 
  fetchIncidentTypesSuccess, 
  fetchIncidentTypesError 
} from './actions';
import context from '../../../../api/api';

import { FETCH_TYPES, FETCH_INCIDENT_TYPES } from './constants';

// справочник для Обращений
function* fetchTypesAsync() {
  try {
    const types = yield call(context.cardIndex.getTree, 0);
    yield put(fetchTypesSuccess(types));
  } catch (error) {
    yield put(fetchTypesError());
  }
}

// справочник для гражданских происшествий 
function* fetchIncidentTypesAsync() {
  try {
    const types = yield call(context.cardIndex.getIncindentTree, 0);
    yield put(fetchIncidentTypesSuccess(types));
  } catch (error) {
    yield put(fetchIncidentTypesError());
  }
}

export function* watchFetchTypes() {
  yield takeEvery(FETCH_TYPES, fetchTypesAsync);
  yield takeEvery(FETCH_INCIDENT_TYPES, fetchIncidentTypesAsync);
}
