import { Iterable } from 'immutable';
import moment from 'moment';

export const isImmutable = (data) => {
  return Iterable.isIterable(data);
};

export const convertFromImmutableToJS = (elt) => {
  if (isImmutable(elt)) {
    return elt.toJS();
  }

  return elt;
};


// Get filter params like a string for the filter panel
export const getFilterParams = (filterValue, columns) => {
  const getFilterParamsForOneColumn = (columnFilterValue) => {
    if (Array.isArray(columnFilterValue[2])) {
      const values = columnFilterValue[2].reduce((result, item) => {
        return `«${result}», «${item}»`;
      });

      return `${values}`;
    }
    return `«${columnFilterValue[2]}»`;
  };

  if (filterValue[1] === 'and') {
    let result = '';
    filterValue.forEach((item, index) => {
      if (index !== 0 && index % 2 === 0) {
        result = `${result} / ${columns.find(column => column.dataField === item[0]).caption}: ${getFilterParamsForOneColumn(item)}`;
      }
      if (index === 0) {
        result = `${columns.find(column => column.dataField === item[0]).caption}: ${getFilterParamsForOneColumn(item)}`;
      }
    });
    return result;
  }
  return `${columns.find(column => column.dataField === filterValue[0]).caption}: ${getFilterParamsForOneColumn(filterValue)}`;
};

export const sagasDelay = ms => new Promise(res => setTimeout(res, ms));

export const formatDate = date => moment(date).format('DD.MM.Y HH:mm');
export const isDateValid = date => moment(date).isValid();

export const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);

  return bytes.map((b, i) => {
    return binaryString.charCodeAt(i);
  });
};

export const formatNumberBox = (val) => {
  return isNaN(val) || val === null ? null : Number(val);
}

export const escapeSingleQuote = (str) => {
  return str.replace(/'/g, ""); // $& means the whole matched string
}

export const fileSizeSI = (a, b, c, d, e) => {
  return (b = Math, c = b.log, d = 1e3, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(e ? 2 : 0)
    + ' ' + (e ? 'КMГTПЭЗЙ'[--e] + 'б' : 'Байт')
}

export const fileSizeIEC = (a, b, c, d, e) => {
  return (b = Math, c = b.log, d = 1024, e = c(a) / c(d) | 0, a / b.pow(d, e)).toFixed(2)
    + ' ' + (e ? 'КMГTПЭЗЙ'[--e] + 'бит' : 'Байт')
}