import React, { Component, Fragment } from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import { Quill } from 'react-quill';
import "./style.css";

export default class AboutPageComponent extends Component {

  render() {
    const { name, description } = this.props.aboutData;

    const options = { readOnly: true, };
    const quill = new Quill('#portal-description', options);
    try {
        const content = JSON.parse(description);
        quill.setContents(content);
    } catch (error) {
    }

    return (
      <Grid>
        <Row>
          <Col>
            <Fragment>
              <div id="portal-description" />
            </Fragment>
          </Col>
        </Row>
      </Grid>
    );
  }
}
