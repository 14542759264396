import context from "./api";
export const getFooterData = () => {
  return [{
    id: 1,
    title: 'Помощь и поддержка',
    elements: [{
      id: 1,
      name: 'Вход и регистрация',
      link: ''
    }, {
      id: 2,
      name: 'Все вопросы и ответы',
      link: '/faq'
    }]
  },  
  {
    id:2, title: 'Памятки и инструкции', elements:[]
  }
  ,{
    id: 3,
    title: 'Информация о портале',
    elements: [{
      id: 1,
      name: 'О портале',
      link: '/about'
    }]
  }, {
    id: 4,
    title: 'Полезные ресурсы',
    elements: [{
      id: 1,
      name: 'МЧС России по Ростовской области',
      link: 'http://61.mchs.gov.ru/'
    }, {
      id: 2,
      name: 'Официальный портал Правительства Ростовской области',
      link: 'http://www.donland.ru/'
    }, {
      id: 3,
      name: '«Служба-112» Ростовской области',
      link: 'http://112ro.donland.ru/'
    }]
  }];
};

export const getHeaderEmergencyData = () => {
  return {
    isEnabled: true,
    title: 'Штормовое предупреждение',
    content: '<p>\nПо состоянию на 20.07.2016 в городе ожидается штормовое предупреждение в связи с обилием проливных дождей и ' +
      'штормового ветра.\nПо возможности - не выходите из дома. Закройте плотно окна, не оставляйте автомобили под рекламными щитами и ' +
      'деревьями.\n</p>\n<br />\n<p>Дополнительная информация на сайте МЧС.</p>\n<p>Телефон горячей линии: <a href="#">8 800 3452311</a>' +
      '</p>'
  };
};

export const getAboutPageData = () => {
  return {
    title: 'Об этом портале',
    content: '<h2>Что такое <i>Lorem Ipsum</i>?</h2><p>Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне.\n <b>Lorem Ipsum</b> является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. </p><p>Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.</p>'
  };
};

export const getAnswerQuestionData = () => {
  return context.portalInfo.getFaqs();
};

export const getAboutData = () => {
  return context.portalInfo.getAbout();
};