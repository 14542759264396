export const SEND_REQUESTPAGE_FORM = 'SEND_REQUESTPAGE_FORM';
export const SEND_REQUESTPAGE_FORM_SUCCESS = 'SEND_REQUESTPAGE_FORM_SUCCESS';
export const SEND_REQUESTPAGE_FORM_ERROR = 'SEND_REQUESTPAGE_FORM_ERROR';
export const GET_ADDRESS = 'GET_ADDRESS';
export const SET_ADDRESS = 'SET_ADDRESS';
export const ADDRESS_LOADED = 'ADDRESS_LOADED';
export const GET_AUTOCOMPLETE_ADDRESS = 'GET_AUTOCOMPLETE_ADDRESS';
export const AUTOCOMPLETE_ADDRESS_LOADED = 'AUTOCOMPLETE_ADDRESS_LOADED';
export const ADDRESS_AND_COORDS_LOADED = 'ADDRESS_AND_COORDS_LOADED';

export const GET_MUNICIPALITIES = 'GET_MUNICIPALITIES';
export const GET_MUNICIPALITIES_SUCCEEDED = 'GET_MUNICIPALITIES_SUCCEEDED';
export const GET_MUNICIPALITIES_FAILED = 'GET_MUNICIPALITIES_FAILED';
