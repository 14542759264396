import React from 'react';
import { Incidents } from '../../components/Incidents';
import { PageTitleComponent } from '../../components/PageTitleComponent';

export default class IncidentsContainer extends React.PureComponent {
  componentDidMount() {
    this.props.getIncidents();
  }

  render() {
    const {
      user,
      onIncidentChange,
      incidentType,
      isUserAuthenticated,
      history
    } = this.props;

    return (
      <React.Fragment>
        <PageTitleComponent title='Обращения'/>
        <Incidents
          userLocation={user && user.MapExtent}
          onIncidentChange={onIncidentChange}
          incidentType={incidentType}
          isUserAuthenticated={isUserAuthenticated}
          history={history}
        />
      </React.Fragment>
    );
  }
}
