import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

class FaqItem extends Component {
    render() {
        const { data } = this.props;
        return (
            <Grid>
                <Row>
                    <Col md={6} sm={6}>
                        {data.question}
                    </Col>
                    <Col md={6} sm={6}>
                        {data.answer}
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default FaqItem;
