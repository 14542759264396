import React from 'react';
import { union } from 'lodash';
import { stringify, parse } from 'qs';
import { AppealsJournal } from '../../components/AppealsJournal';
import { StatusCell } from '../../components/customTableCells/StatusCell';
import { DateCreatedCell } from '../../components/customTableCells/DateCreatedCell';
import { initResultsWithDictionaries } from '../../helpers/dictionaries';
import { get } from 'immutable';

const columns = [{
  dataField: 'stateName',
  caption: 'Статус',
  dataType: 'string',
  width: '15%',
  cellComponent: StatusCell,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: true,
  allowFiltering: true,
}, {
  dataField: 'type.name',
  caption: 'Тип происшествия',
  dataType: 'string',
  width: '15%',
  cellComponent: null,
  allowSorting: false,
  cssClass: 'bold',
  allowHeaderFiltering: true,
  allowFiltering: true,
}, {
  dataField: 'created',
  caption: 'Дата и время регистрации',
  dataType: 'datetime',
  width: '15%',
  cellComponent: DateCreatedCell,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: true,
  allowFiltering: true,
}, {
  dataField: 'shortAddress',
  caption: 'Адрес',
  dataType: 'string',
  width: "20%",
  cellComponent: null,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: false,
  allowFiltering: true,
}, {
  dataField: 'addressNote',
  caption: 'Дополнительная информация о местоположении',
  dataType: 'string',
  width: '15%',
  cellComponent: null,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: false,
  allowFiltering: true,
  // hidingPriority: 0

}, {
  dataField: 'comment',
  caption: 'Краткое описание',
  dataType: 'string',
  width: '15%',
  cellComponent: null,
  allowSorting: false,
  cssClass: undefined,
  allowHeaderFiltering: false,
  allowFiltering: true,
  // hidingPriority: 1
}];
const defaultTotalDataNumber = 0;

export default class AppealsJournalContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  componentDidMount() {
    const { appealsData, getAppeals } = this.props;
    if (!appealsData.data || (appealsData && !appealsData.data.length))
      getAppeals();
  }

  currentPage = 0;
  onContentReady = (e) => {
    if (!this.state.collapsed) {
      e.component.expandRow(['EnviroCare']);
      this.setState({
        collapsed: true
      });
    }
    const {
      history: {
        location
      }
    } = this.props;
    const urlParams = parse(location.search, { ignoreQueryPrefix: true });
    const page = parseInt(urlParams && urlParams.page || 1) - 1;
    if (e.component.getDataSource().items().length === 0)
      return;
    this.currentPage = page;
    e.component.pageIndex(page);
  };

  onOptionChanged = (e) => {
    const { history, history: { location } } = this.props;
    if (e.fullName === "paging.pageIndex" || e.fullName.indexOf('.filterValue') > 0) {
      let page = e.fullName === "paging.pageIndex" ? (e.value) : 0;
      if (page === this.currentPage) return;
      page++;
      let urlParams = parse(location.search, { ignoreQueryPrefix: true });
      let type =  urlParams.type;
      let mapPosition =  urlParams.mapPosition;
      const params = stringify({ page, type, mapPosition }, { addQueryPrefix: true });

      history.push({
        pathname: ``,
        search: `${params}`
      });
    }
  }

  render() {
    const {
      statuses,
      types,
      redirectToCard,
      appealsData,
    } = this.props;
    const { loading } = appealsData;
    const appeals = initResultsWithDictionaries({ statuses, types, dataMap: { data: union(appealsData.data) } });

    return (
      <AppealsJournal
        onContentReady={this.onContentReady}
        columnsData={get(appeals, 'data', [])}
        totalDataNumber={(appeals && appeals.data.length) || defaultTotalDataNumber}
        redirectToCard={redirectToCard}
        columns={columns}
        onOptionChanged={this.onOptionChanged}
        loading={loading}
      />
    );
  }
}
