import { connect } from 'react-redux';
import { parse } from 'qs';
import Container from './Container';
import UserDataHOC from '../HOC/UserData';
import DictionariesHOC from '../HOC/dictionaries';
import { getAppeal, downloadFile, downloadAllFiles } from './actions';
import { convertFromImmutableToJS } from '../../helpers/common';
import { storeName } from './reducer';

const mapStateToProps = (state, props) => {
  const { location } = props;
  const params = parse(location.search, { ignoreQueryPrefix: true });

  const incident = (location.state && location.state.incidentId) || params.incidentId;
  const card = (location.state && location.state.cardId) || params.cardId;

  return {
    appeal: convertFromImmutableToJS(state.getIn([storeName, 'appeal'])),
    incidentId: incident,
    cardId: card,
    errorMessage: convertFromImmutableToJS(state.getIn([storeName, 'errorMessage'])),
    loading: convertFromImmutableToJS(state.getIn([storeName, 'loading'])),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { location } = props;
  const params = parse(location.search, { ignoreQueryPrefix: true });

  const incident = (location.state && location.state.incidentId) || params.incidentId;
  const card = (location.state && location.state.cardId) || params.cardId;

  return {
    getAppeal: () => {
      dispatch(getAppeal(incident, card));
    },
    downloadFile: (fileId, fileName) => {
      dispatch(downloadFile(fileId, fileName));
    },
    downloadAllFiles: (fileNames, fileIds) => {
      dispatch(downloadAllFiles(fileNames, fileIds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DictionariesHOC(UserDataHOC(Container)));
