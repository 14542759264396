import React from 'react';
import Footer from '../../components/layout/Footer';

export default class FooterContainer extends React.PureComponent {
  componentDidMount() {
    this.props.getFooterData();
    this.props.getReminderSections();
  }

  render() {
    const { footerData, userLogin, isUserAuthenticated, footerReminderSections } = this.props;

    return (
      <Footer
        footerData={footerData}
        userLogin={userLogin}
        isUserAuthenticated={isUserAuthenticated}
        footerReminderSections={footerReminderSections}
      />
    );
  }
}