import { connect } from 'react-redux';
import Container from './Container';
import { getFooterData as loadFooterData } from './actions';
import { storeName } from './reducer';
import { convertFromImmutableToJS } from '../../helpers/common';
import { loginUser, setIsUserAuthenticated } from '../AuthPage/actions';
import UserDataHOC from '../HOC/UserData';
import { getReminderSections } from '../RemindersPage/actions';


const mapStateToProps = (state) => {
  return {
    footerData: convertFromImmutableToJS(state.getIn([storeName, 'footerData'])),
    footerReminderSections: convertFromImmutableToJS(state.getIn([storeName, 'footerReminderSections'])),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFooterData: () => {
      dispatch(loadFooterData());
    },
    userLogin: () => {
      dispatch(loginUser());
    },
    getReminderSections: () => {
      dispatch(getReminderSections());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDataHOC(Container));