import React, { Component, Fragment } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import FaqPageComponent from '../../components/FaqPage';
import { PageTitleComponent } from '../../components/PageTitleComponent';
import FaqPageHeader from '../../components/FaqPage/FaqPageHeader';

export default class FaqPage extends Component {
  componentDidMount() {
    const { getFaqData } = this.props;
    getFaqData();
  }

  render() {
    return (
      <Fragment>
        <PageTitleComponent title="Вопросы и ответы" />
        <Header />
        <FaqPageHeader />
        <FaqPageComponent faqs={this.props.faqData} />
        <Footer />
      </Fragment>
    );
  }
}