import React, { Component } from 'react';
import { Grid, Row } from 'react-bootstrap';
import Breadcrumbs from '../../../containers/Breadcrumbs';
import bem from '../../../lib/bem';
// import './style.css';

const block = 'about-header';

class FaqPageHeader extends Component {
  render() {
    return (
      <div>
        <Grid>
          <Row>
            <div className={bem({ block })}>
              <div className={bem({ block, elem: 'title' })}>
                <h1 className={bem({ block, elem: 'title-content' })}>Все вопросы и ответы </h1>
                <Breadcrumbs />
              </div>
            </div>
          </Row>
        </Grid>
        <div className={bem({ block, elem: 'divider' })} />
      </div>
    );
  }
}
export default FaqPageHeader;