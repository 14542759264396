import React, { Component, Fragment } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import AboutPageComponent from '../../components/AboutPage';
import { PageTitleComponent } from '../../components/PageTitleComponent';
import AboutPageheader from '../../components/AboutPage/AboutPageheader';

export default class AboutPage extends Component {
  componentDidMount() {
    const { getAboutPageData } = this.props;
    getAboutPageData();
  }

  render() {
    const { aboutPageData } = this.props;    
    return (
      <Fragment>
        <PageTitleComponent title="О портале" />
        <Header />
        <AboutPageheader />
        <AboutPageComponent aboutData={aboutPageData} />
        <Footer />
      </Fragment>
    );
  }
}