import React, { Fragment } from 'react';
import bem from '../../lib/bem';
import { CustomDataGrid } from '../../components/CustomDataGrid';
import { EmptyBlock } from '../../components/EmptyBlock';
import { Modal, ModalHeader, ModalTitle, ModalBody } from 'react-bootstrap';
import { IncidentModal } from '../../components/modals/IncidentModal'
import './style.css';

const block = 'home-journals';

export class IncidentsJournal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      data: null
    };
  }

  openModal = (e) => {
    this.setState({
      data: e.data,
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  renderBodyModel() {
    const { data } = this.state;
    return (
      <IncidentModal incident={data}></IncidentModal>
    )
  }

  renderTitleModel() {
    const { data } = this.state;
    return (
      <div className={bem({ block, elem: 'modal-title-block' })}>
        <div className={bem({ block, elem: 'modal-title' })}>
          Карточка происшествия
        </div>
      </div>
    );
  }

  render() {
    const {
      columnsData,
      onContentReady,
      columns,
      totalDataNumber
    } = this.props;

    return (
      <React.Fragment>
        <div className={bem({ block })}>
          {columnsData && columnsData.length === 0 ?
            <EmptyBlock
              text="Происшествий нет"
            /> :
            <div>
              <CustomDataGrid
                columnsData={columnsData}
                onContentReady={onContentReady}
                columns={columns}
                totalDataNumber={totalDataNumber}
                onClickRow={this.openModal}
                wordWrapEnabled={false}
              />
            </div>
          }
        </div>

        <Modal show={this.state.visible} bsSize="lg" effect="fadeInUp" onHide={() => this.closeModal()}>
          <ModalHeader closeButton>
            <ModalTitle className={bem({ block, elem: 'title' })}>
              {this.renderTitleModel()}
            </ModalTitle>
          </ModalHeader>
          <ModalBody className={bem({ block, elem: 'body' })}>
            {this.renderBodyModel()}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
