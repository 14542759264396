import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_REMINDERS_CONTENT,
  GET_REMINDER_LIST,
  GET_REMINDER_SECTIONS,
  GET_REMINDER_SECTION_DOCUMENTS
} from './constants';
import {
  remindersContentLoaded,
  reminderListLoaded,
  remindersSectionsLoaded,
  remindersSectionDocumentsLoaded
} from './actions';
import context from '../../api/api';

function* loadRemindersContent({ payload: { id } }) {
  const remindersResponse = yield call(context.documents.get, id);
  yield put(remindersContentLoaded(remindersResponse));
}

function* loadReminderList() {
  const response = yield call(context.documents.getTree);
  yield put(reminderListLoaded(response));
}

function* loadReminderSections() {
  const response = yield call(context.documents.getSections);
  yield put(remindersSectionsLoaded(response));
}

function* loadReminderSectionDocuments({ payload: { id } }) {
  const response = yield call(context.documents.getSectionById, id);
  yield put(remindersSectionDocumentsLoaded(response));
}

export function* remindersSaga() {
  yield takeEvery(GET_REMINDERS_CONTENT, loadRemindersContent);
  yield takeEvery(GET_REMINDER_LIST, loadReminderList);
  yield takeEvery(GET_REMINDER_SECTIONS, loadReminderSections);
  yield takeEvery(GET_REMINDER_SECTION_DOCUMENTS, loadReminderSectionDocuments);
}
