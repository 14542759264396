import React from 'react';
import { Map, Marker, ZoomControl } from 'react-leaflet';
import WMTSTileLayer from "react-leaflet-wmts";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import moment from 'moment';
import bem from '../../../lib/bem';
import { STATUS_DONE, STATUS_NEW, STATUS_WORK, STATUS_REJECTED } from '../../../constants/statuses';
import context from '../../../api/api';
import './style.css';

const block = 'incident-modal';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const zoom = 17;

export class IncidentModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      openMap: true,
      gisServerUri: '',
      gisServerLayers: ''
    };
    context.settings.getGisServerSettings()
      .then((settings) =>
        this.setState({ gisServerUri: settings.Uri, gisServerLayers: settings.Layers })
      );
  }

  getStatusMods = (status) => {
    return status ? {
      done: status === STATUS_DONE,
      new: status === STATUS_NEW,
      'at-work': status === STATUS_WORK,
      rejected: status === STATUS_REJECTED
    } : {};
  };

  mapBlockMods = () => {
    const { openMap } = this.state;
    return {
      open: openMap
    };
  };

  changeMapView = () => {
    this.setState(prevState => ({ openMap: !prevState.openMap }));
  };

  renderCategory = (isDanger, isPotentialDanger) => {
    let categoryText = 'Категория не задана';
    if (isDanger) {
      categoryText = "Категория: ЧС";
    } else if (isPotentialDanger) {
      categoryText = "Категория: Признаки ЧС";
    }
    return (<p className={(!isDanger && !isPotentialDanger) ? 'category-none': ''}>{categoryText}</p>);
  }

  render() {
    const { incident } = this.props;
    if (incident && incident.incidentId) {
      const {
        incident: {
          missionId,
          created,
          stateName,
          stateId,
          cardIndexName,
          dispatchServiceName,
          shortAddress,
          fullAddress,
          comment,
          latitude,
          longitude,
          isDanger,
          isPotentialDanger,
        }
      } = this.props;

      return (
        <div className={bem({ block })} id="incident_modal">
          <div className={bem({ block, elem: 'date-status' })}>
            <div className={bem({ block, elem: 'date' })}>
              <span className={bem({ block, elem: 'date-title' })}>Дата и время регистрации: </span>
              {created && moment(created).format('DD.MM.YYYY HH:mm')}
            </div>
            <div className={bem({ block, elem: 'status', mods: this.getStatusMods(stateId) })}>
              {stateName}
            </div>
          </div>

          <div className={bem({ block, elem: 'incident-block' })}>
            <div className={bem({ block, elem: 'incident' })}>
              <div className={bem({ block, elem: 'incident-title-subtitle' })}>
                <div className={bem({ block, elem: 'incident-title' })}>
                  {cardIndexName}
                </div>
                <div className={bem({ block, elem: 'incident-subtitle' })}>
                  {dispatchServiceName}
                </div>
              </div>
              <div className={bem({ block, elem: 'incident-number' })}>
                {missionId}
              </div>
            </div>
            <div className={bem({ block, elem: 'incident-category' })}>
              {this.renderCategory(isDanger, isPotentialDanger)}
            </div>
            <div className={bem({ block, elem: 'address' })}>
              <div className={bem({ block, elem: 'address-text' })}>
                <span className="icon-map" />
                {fullAddress}
              </div>
              {latitude && longitude
                && (
                  <div className={bem({ block, elem: 'address-coord' })}>
                    {latitude}
                    ,&nbsp;
                    {longitude}
                  </div>
                )
              }
            </div>
          </div>

          {latitude && longitude
            ? (
              <div className={bem({ block, elem: 'incident-map-block' })}>
                <div className={bem({ block, elem: 'incident-map-title-arrow' })} onClick={this.changeMapView}>
                  <div className={bem({ block, elem: 'incident-map-title' })}>
                    Происшествие на карте
                  </div>
                  <span className="icon-arrow-right" />
                </div>
                <div className={bem({ block, elem: 'incident-map-content', mods: this.mapBlockMods() })}>
                  <Map
                    className={bem({ block, elem: 'incident-map' })}
                    center={[latitude, longitude]}
                    zoom={zoom}
                    zoomControl={false}
                    scrollWheelZoom={false}
                    maxZoom={17}
                    minZoom={5}
                  >
                    <WMTSTileLayer
                      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                      url={this.state.gisServerUri}
                      layers={this.state.gisServerLayers}
                      layer={this.state.gisServerLayers}
                      tilematrixSet="EPSG:900913"
                      srs="EPSG:900913"
                      format="image/png"
                    />
                    <ZoomControl
                      zoomInTitle="Увеличить"
                      position="topright"
                      zoomOutTitle="Уменьшить"
                    />
                    <Marker position={[latitude, longitude]} />
                  </Map>
                </div>
              </div>
            ) : ''
          }

          <div className={bem({ block, elem: 'comment' })}>
            <div className={bem({ block, elem: 'comment-title' })}>
              Сопроводительный текст
            </div>
            <div className={bem({ block, elem: 'comment-content' })}>
              {comment}
            </div>
          </div>
        </div>
      );
    }

    return '';
  }
}
