import {
  LOAD_APPEAL,
  APPEAL_LOADED,
  DOWNLOAD_FILE,
  DOWNLOAD_ALL_FILES,
  APPEAL_LOAD_FAILED,
} from './constants';

export const getAppeal = (incidentId, cardId) => {
  return {
    type: LOAD_APPEAL,
    payload: {
      incidentId,
      cardId
    }
  };
};

export const appealLoaded = (data) => {
  return {
    type: APPEAL_LOADED,
    payload: {
      data
    }
  };
};

export const appealLoadFailed = (error) => {
  return {
    type: APPEAL_LOAD_FAILED,
    payload: {
      error
    }
  };
};

export const downloadFile = (fileId, fileName) => {
  return {
    type: DOWNLOAD_FILE,
    payload: {
      fileId,
      fileName
    }
  };
};

export const downloadAllFiles = (fileNames, fileIds) => {
  return {
    type: DOWNLOAD_ALL_FILES,
    payload: {
      fileNames,
      fileIds
    }
  };
};