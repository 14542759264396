export const GET_REMINDER_LIST = 'GET_REMINDER_LIST';
export const REMINDER_LIST_LOADED = 'REMINDER_LIST_LOADED';

export const GET_REMINDERS_CONTENT = 'GET_REMINDERS_CONTENT';
export const REMINDERS_CONTENT_LOADED = 'REMINDERS_CONTENT_LOADED';

export const SET_REMINDER_NODE_EXPANDED = 'SET_REMINDER_NODE_EXPANDED';
export const SET_REMINDER_NODE_COLLAPSED = 'SET_REMINDER_NODE_COLLAPSED';

export const GET_REMINDER_SECTIONS = 'GET_REMINDER_SECTIONS';
export const REMINDER_SECTIONS_LOADED = 'REMINDER_SECTIONS_LOADED';

export const GET_REMINDER_SECTION_DOCUMENTS = 'GET_REMINDER_SECTION_DOCUMENTS';
export const REMINDER_SECTION_DOCUMENTS_LOADED = 'REMINDER_SECTION_DOCUMENTS_LOADED';