import { connect } from 'react-redux';
import Container from './Container';
import { storeName } from './reducer';
import { convertFromImmutableToJS } from '../../helpers/common';
import { getFaqData } from './actions';

const mapStateToProps = (state) => {
    return convertFromImmutableToJS(state.get(storeName));
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFaqData: () => {
            dispatch(getFaqData());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);

