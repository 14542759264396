import React, { Component } from 'react';
import { Grid, Row, Col, Accordion, Card } from 'react-bootstrap';
import FaqItem from "./FaqItem";
import bem from '../../lib/bem';
import './style.css';

const block = "faq-content";

export default class FaqPageComponent extends Component {
    render() {
        const { faqs } = this.props;
        if (!faqs || faqs.length == 0) {
            return (<div></div>);
        }
        return (
            <Grid>
                <div className={bem({ block })}>
                    <Row>
                        <Col>
                            {faqs && faqs.map((item, i) => {
                                return (
                                    <FaqItem data={item} key={i} />
                                );
                            })}
                        </Col>
                    </Row>
                </div>
            </Grid >
        );
    }
}
