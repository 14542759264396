import { put, call, takeEvery } from 'redux-saga/effects';
import { aboutPageDataLoaded, aboutPageDataLoadFailed } from './actions';
import { getAboutData } from '../../api/common';
import { GET_ABOUT_PAGE_DATA } from './constants';

function* loadAboutPageData() {
  try {
    const response = yield call(getAboutData);
    if (response)
      yield put(aboutPageDataLoaded(response));
  } catch (error) {
    console.error(error.toString());
    yield put(aboutPageDataLoadFailed(error));
  }
}

export function* aboutPageSaga() {
  yield takeEvery(GET_ABOUT_PAGE_DATA, loadAboutPageData);
}