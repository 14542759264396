import { fromJS } from 'immutable';
import { APPEAL_LOADED, APPEAL_LOAD_FAILED, LOAD_APPEAL } from './constants';

const appealLoaded = (state, { data }) => {
  return state
    .set('loading', false)
    .set('appeal', fromJS(data));
};

const appealLoadFailed = (state, { error }) => {
  return state
    .set('errorMessage', error.Message)
    .set('loading', false)
    .set('appeal', {});
}

const appealLoading = (state, value) => {
  return state.set('loading', value);
}

export const storeName = 'appealCard';

const initialState = fromJS({
  appeal: {},
  errorMessage: '',
  loading: false
});

export default {
  [storeName]: (state = initialState, action) => {
    switch (action.type) {
      case APPEAL_LOADED:
        return appealLoaded(state, action.payload);
      case APPEAL_LOAD_FAILED:
        return appealLoadFailed(state, action.payload);
      case LOAD_APPEAL:
        return appealLoading(state, true);
      default:
        return state;
    }
  }
};