import React from 'react';
import bem from '../../../lib/bem';
import './style.css';
import {STATUS_DONE, STATUS_NEW, STATUS_REJECTED, STATUS_WORK} from "../../../constants/statuses";

const block = 'status-cell';

export const StatusCell = (cellData) => {
  const getStatusMods = () => {
    return cellData.data.status ? {
      done: cellData.data.stateId === STATUS_DONE,
      new: cellData.data.stateId === STATUS_NEW,
      'at-work': cellData.data.stateId === STATUS_WORK,
      rejected: cellData.data.stateId === STATUS_REJECTED
    } : {};
  };

  return (
    <div className={bem({ block })}>
      <div className={bem({ block, elem: 'name', mods: getStatusMods() })}>
        {cellData.data.status ? cellData.data.status.name : ''}
      </div>
    </div>
  );
};
