import { fromJS } from 'immutable';
import { FAQ_DATA_LOADED } from './constants';

export const storeName = 'faq';

const faqDataLoaded = (state, { faqData }) => {
    return state.set('faqData', faqData);
}

const initialState = fromJS({
    faqData: []
});

export default {
    [storeName]: (state = initialState, action) => {
        switch (action.type) {
            case FAQ_DATA_LOADED:
                return faqDataLoaded(state, action.payload);
            default:
                return state;
        }
    }
}